// src/services/axiosInstance.js

import axios from 'axios';
import { DASHBOARD_BASE_URL } from '../constant';

// Create an Axios instance
const dashboardApi = axios.create({
  baseURL: DASHBOARD_BASE_URL, // Replace with your API base URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request Interceptor: Automatically add token to request headers
dashboardApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor: Check for 401 errors and redirect to session-expired
dashboardApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Token is no longer valid, handle redirection
      localStorage.removeItem('token'); // Clear token
      window.location.href = '/session-expired'; // Redirect to session expired page
    }
    return Promise.reject(error);
  }
);

export default dashboardApi;
