import React, { useEffect, useState, useContext } from "react";
import BotNameDropdown from "../components/SelectGroup/BotNameDropdown";
import apiBotConfiguration from "../services/apiBotConfiguration";
import { BASE_URL } from "../constant";
import toast from "react-hot-toast";
import AddressDropdown from "../components/AddressDropdown";
import { AuthContext } from '../components/PrivateRoutes'
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";


const DASHBOARD_BOTS = [
  "DailyKPI",
  "Fehlende Doku",
  "Patienten_Ohne_Termin",
  "Patienten_Ohne_Leistung",
  "Umsatz",
];

const BotConfiguration = () => {
  const { t } = useTranslation();
  const {user} = useContext(AuthContext)
  const [botInfo, setBotInfo] = useState([]);
  const [addressInfo, setAddressInfo] = useState([]);
  const [selectedBot, setSelectedBot] = useState(null);
  const [selectedAddresses, setSelectedAddresses] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [run, setRun] = useState(false);
  const [addressStatus, setAddressStatus] = useState({});
  const [dashboardBots, setDashboardBots] = useState([])
  const {token} = useContext(AuthContext)
  const navigate = useNavigate()

  const handleChange = (event) => {
    const value = JSON.parse(event.target.value)
    if(value.botname === 'Dashboard') {
      setSelectedBot(value);
      setFormData({})
    }else {
      setSelectedBot(value);
      setFormData({})
    }
    
  };

  const handleCheckboxChange = (address) => {
    setSelectedAddresses((prev) =>
      prev.includes(address)
        ? prev.filter((a) => a !== address)
        : [...prev, address]
    );
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const fetchBotInfo = async () => {
    try {
      const response = await apiBotConfiguration.get(`${BASE_URL}/api/bots`, {
        headers: {
          'x-access-token': token
        }
      });
      setBotInfo(response.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const fetchAddressInfo = async () => {
    try {
      const response = await apiBotConfiguration.get(`${BASE_URL}/api/addresses`, {
        headers: {
          'x-access-token': token
        }
      });
      setAddressInfo(response.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleFormChange = (paramName, paramValue) => {
    setFormData((prevState) => ({
      ...prevState,
      [paramName]: paramValue,
    }));
  };

  const clickHandler = async () => {
    try {
      if (!selectedBot) {
        toast.error("Please select a bot");
        return;
      }
  
      const address = selectedAddresses.map((address) => address);
      let pendingPromises;
  
      // Check if the selected bot is a Dashboard Bot

      if (selectedBot.botname === 'Dashboard') {
        pendingPromises = address.flatMap((address) => {
          return dashboardBots.map((dashboardBot) => {
            const body = {
              itemData: {
                Name: dashboardBot.botname + "_Queue",
                Priority: "High",
                SpecificContent: formData,
              },
              location: address,
            };
            return apiBotConfiguration
              .post(`${BASE_URL}/api/add-queue`, body, {
                headers: {
                  'x-access-token': token
                }
              })
              .then(() => ({ address, status: "success" }))
              .catch(() => ({ address, status: "failure" }));
          });
        });
      } else {
        pendingPromises = address.map((address) => {
          const body = {
            itemData: {
              Name: selectedBot.botname + "_Queue",
              Priority: "High",
              SpecificContent: formData,
            },
            location: address,
          };
          return apiBotConfiguration
            .post(`${BASE_URL}/api/add-queue`, body, {
              headers: {
                'x-access-token': token
              }
            })
            .then(() => ({ address, status: "success" }))
            .catch(() => ({ address, status: "failure" }));
        });
      }
  
      setRun(true);
      const results = await Promise.all(pendingPromises);
      const newStatus = results.reduce((acc, { address, status }) => {
        acc[address] = status;
        return acc;
      }, {});
      setAddressStatus(newStatus);
      toast.success(t("Bot run completed!"));
      
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.error?.message?.message);
    }
    setRun(false);
  };

  useEffect(() => {
    fetchBotInfo();
    fetchAddressInfo();
  }, []);

  useEffect(() => {
    setDashboardBots(botInfo.filter((bot) => DASHBOARD_BOTS.includes(bot.botname)))
  }, [botInfo])

  if(user.role !== 'admin' && user.role !== 'superadmin') {
    navigate('/visualization')
    return
  }

  return (
    <div className="w-full flex justify-center">
      <div className="max-w-4xl w-full rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-xl text-black dark:text-white">
          {t('Bot Configuration')}
          </h3>
        </div>
        <form action="#">
          <div className="p-6.5">
            <BotNameDropdown
              selectedBot={selectedBot}
              handleChange={handleChange}
              DASHBOARD_BOTS={DASHBOARD_BOTS}
              botInfo={botInfo}
            />
            <AddressDropdown
              selectedAddresses={selectedAddresses}
              setSelectedAddresses={setSelectedAddresses}
              addresses={addressInfo}
              handleCheckboxChange={handleCheckboxChange}
              toggleDropdown={toggleDropdown}
              isDropdownOpen={isDropdownOpen}
              addressStatus={addressStatus}
            />
             {selectedBot && selectedBot.params.length > 0 && selectedBot.params.map((param) => {
                const commonProps = {
                    key: param.id,
                    className: "block w-full border border-gray-300 rounded-lg py-2 px-3 text-gray-900 placeholder-gray-400",
                    onChange: (e) => handleFormChange(param.parameter_name, e.target.value),
                    name: param.parameter_name,
                    id: param.id,
                };

                if (param.parameter_type === 'month') {
                    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                    return (
                        <div className="mb-6" key={param.id}>
                            <label htmlFor={param.parameter_name} className="block text-sm font-medium text-gray-700 mb-2">
                                {`Select ${param.parameter_name}`}
                            </label>
                            <select {...commonProps}>
                                <option value="">Please select</option>
                                {months.map((month, index) => (
                                    <option value={index + 1} key={index + 1}>
                                        {month}
                                    </option>
                                ))}
                            </select>
                        </div>
                    );
                }

                if (param.parameter_type === "array") {
                    const values = JSON.parse(param.parameter_values);
                    return (
                        <div className="mb-6" key={param.id}>
                            <label htmlFor={param.parameter_name} className="block text-sm font-medium text-gray-700 mb-2">
                                {`Select ${param.parameter_name}`}
                            </label>
                            <select {...commonProps} className="relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white">
                                <option value="">Please select</option>
                                {values.map((value) => (
                                    <option value={value} key={value}>
                                        {value}
                                    </option>
                                ))}
                            </select>
                        </div>
                    );
                }

                return (
                    <div className="mb-6" key={param.id}>
                        <label htmlFor={param.parameter_name} className="block text-sm font-medium text-gray-700 mb-2">
                            {`Enter ${param.parameter_name}`}
                        </label>
                        <input
                            type={param.parameter_type}
                            {...commonProps}
                            placeholder={`Enter ${param.parameter_name}`}
                            value={formData[param.parameter_name] || ""}
                            className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:bg-form-input dark:text-white"
                        />
                    </div>
                );
            })}
            <button
              onClick={clickHandler}
              className={`uppercase flex items-center justify-center w-full rounded p-3 font-medium text-gray text-sm ${
                run
                  ? "bg-blue-600 cursor-not-allowed"
                  : "bg-primary hover:bg-opacity-90"
              }`}
              disabled={run}
            >
              {run ? (
                <svg
                  className="animate-spin h-5 w-5 mr-2 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8z"
                  ></path>
                </svg>
              ) : null}
              {run ? t("Running...") : t("Run Bot")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BotConfiguration;
