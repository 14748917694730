import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const AddressDropdown = ({
  selectedAddresses,
  addresses,
  setSelectedAddresses,
  handleCheckboxChange,
  isDropdownOpen,
  toggleDropdown,
  addressStatus,
}) => {
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const dropdownRef = useRef(null);
  const [selectAll, setSelectAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
const filteredAddresses = addresses.filter((address) =>
  address.displayName.toLowerCase().includes(searchQuery.toLowerCase())
);
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isDropdownOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        toggleDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen, toggleDropdown]);

  const handleSelectAllChange = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setSelectedAddresses(addresses.map((address) => address.displayName));
    } else {
      setSelectedAddresses([]);
    }
  };

  return (
    <div className="relative mt-6" ref={dropdownRef}>
  <label htmlFor="address" className="mb-2.5 block text-black dark:text-white">
    {t("Select Addresses")}
  </label>
  <div className="relative">
    <div
      className={`relative flex items-center justify-between z-20 w-full appearance-none rounded border border-stroke bg-transparent py-2 px-2 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary ${
        isOptionSelected ? 'text-black dark:text-white' : ''
      }`}
      onClick={() => toggleDropdown(!isDropdownOpen)}
    >
      <span className="px-1 py-1 text-gray-900">
        {selectedAddresses.length > 0 ? selectedAddresses.join(", ") : t("Select Addresses")}
      </span>
      <svg
        className={`w-5 h-5 text-gray-500 transform ${isDropdownOpen ? "rotate-180" : ""}`}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path d="M19 9l-7 7-7-7" />
      </svg>
    </div>
    {isDropdownOpen && (
      <div className="absolute z-50 mt-2 bg-white border border-stroke rounded-lg shadow-lg w-full dark:border-form-strokedark dark:bg-form-input">
        {/* Search input */}
        <div className="p-2 border-b border-stroke">
          <input
            type="text"
            placeholder={t("Search Addresses")}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full px-3 py-2 text-sm bg-white border border-stroke dark:border-form-strokedark dark:bg-form-input"
          />
        </div>

        {/* Select All */}
        <div className="px-4 py-2 border-b border-stroke">
          <label className="flex items-center space-x-2 cursor-pointer">
            <div className="relative">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAllChange}
                className="sr-only"
              />
              <div
                className={`flex h-5 w-5 items-center justify-center rounded border ${
                  selectAll && 'border-primary bg-gray dark:bg-transparent'
                }`}
              >
                <span className={`opacity-0 ${selectAll && '!opacity-100'}`}>
                  <svg
                    width="11"
                    height="8"
                    viewBox="0 0 11 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                      fill="#3056D3"
                      stroke="#3056D3"
                      strokeWidth="0.4"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>
            <span className="text-sm text-gray-700">{t("Select All")}</span>
          </label>
        </div>

        {/* Filtered Address List */}
        <div className="max-h-60 overflow-y-auto">
          {filteredAddresses.map((address) => (
            <label
              key={address.displayName}
              className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
            >
              <div className="relative">
                <input
                  type="checkbox"
                  value={address.displayName}
                  checked={selectedAddresses.includes(address.displayName)}
                  onChange={() => handleCheckboxChange(address.displayName)}
                  className="sr-only"
                />
                <div
                  className={`flex h-5 w-5 items-center justify-center rounded border ${
                    selectedAddresses.includes(address.displayName) && 'border-primary bg-gray dark:bg-transparent'
                  }`}
                >
                  <span className={`opacity-0 ${selectedAddresses.includes(address.displayName) && '!opacity-100'}`}>
                    <svg
                      width="11"
                      height="8"
                      viewBox="0 0 11 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                        fill="#3056D3"
                        stroke="#3056D3"
                        strokeWidth="0.4"
                      ></path>
                    </svg>
                  </span>
                </div>
              </div>
              <span className="ml-2 text-sm text-gray-900">{address.displayName}</span>
            </label>
          ))}
        </div>
      </div>
    )}
  </div>

  <div className="my-4">
    <h3 className="font-medium text-gray-700">{t("Selected Addresses:")}</h3>
    {selectedAddresses.length > 0 ? (
      <ul className="list-disc list-inside text-sm text-gray-900">
        {selectedAddresses.map((location) => (
          <li key={location} className="flex items-center">
            {location}
            {addressStatus[location] === "success" && <span className="ml-2 text-green-500">✅</span>}
            {addressStatus[location] === "failure" && <span className="ml-2 text-red-500">❌</span>}
          </li>
        ))}
      </ul>
    ) : (
      <p className="text-sm text-gray-500">{t("No addresses selected")}</p>
    )}
  </div>
</div>

  );
};

export default AddressDropdown;
