import React from "react";
import ListView from "./ListView";
import { useTranslation } from "react-i18next";

const VersionHistoryTable = ({
  selectedDate,
  tableData,
  selectedAddresses,
}) => {
  const {t} = useTranslation()
  const headers = [
    { key: "DailyKPI_Sales", label: t("Performance") },
    { key: "DailyKPI_Appointment", label: t("Patient with appointment") },
    { key: "DailyKPI_Patienten_Ohne_Termin", label: t("Patients without an appointment") },
    { key: "DailyKPI_Treatment", label: t("Patients with performance") },
    { key: "Patient_Ohne_Leistung", label: t("Patients without service") }, 
    { key: "Patienten_No_Show", label: t("Patienten no-show") }, 
    { key: "DailyKPI_No_FollowUp_Treatment", label: t("Patients without a follow-up appointment") },
    { key: "DailyKPI_PatientId_With_Missing_Document", label: t("Missing/incorrect documentation") },
    { key: "DailyKPI_MVZ_Karten_fehlen", label: t("Insurance cards are currently missing. Q") },
    { key: "DailyKPI_MVZ_Karten_ohne_Leistung", label: t("Insurance cards without benefits current. Q") },
  ];
  return (
    <div>
      {/* Header with date and location */}
      <div className="flex mb-4 flex-col z-[10000] md:flex-row items-center justify-between gap-3 w-full sticky top-0">
        <h4 className="text-xl font-semibold text-black dark:text-white">
          {t("Date")}: {selectedDate}
        </h4>
        <h4 className="text-xl font-semibold text-black dark:text-white">
          {t("Location")}: {selectedAddresses}
        </h4>
      </div>

      {/* Table Content */}
      <div className="overflow-auto max-h-[calc(100vh-200px)] bg-gray-100 dark:bg-gray-700 flex w-full rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="flex">
          {headers.map(({ key, label }, index) => {
            if (key === "Patient_Ohne_Leistung" || key === "Patienten_No_Show") {
              // Special case handling for "DailyKPI_Patienten_Ohne_Leistung"
              const patientData = tableData.find(
                (data) => Object.keys(data)[0] === "DailyKPI_Patienten_Ohne_Leistung"
              );
              if (patientData) {
                const { DailyKPI_Patienten_Ohne_Leistung } = patientData;

                let Patient_Ohne_Leistung = [],
                  Patienten_No_Show = [];

                DailyKPI_Patienten_Ohne_Leistung.forEach((d) => {
                  Patient_Ohne_Leistung.push({
                    Patient_Ohne_Leistung: d.Patient_Ohne_Leistung,
                    createdAt: d.createdAt,
                  });
                  Patienten_No_Show.push({
                    Patienten_No_Show: d.Patienten_No_Show,
                    createdAt: d.createdAt,
                  });
                });

                if (key === "Patient_Ohne_Leistung") {
                  return (
                    <div
                      key={index}
                      className="flex-1 border-r border-stroke dark:border-strokedark"
                    >
                      <ListView header={label} data={{ Patient_Ohne_Leistung }} />
                    </div>
                  );
                }

                if (key === "Patienten_No_Show") {
                  return (
                    <div
                      key={index}
                      className="flex-1  border-stroke dark:border-strokedark"
                    >
                      <ListView header={label} data={{ Patienten_No_Show }} />
                    </div>
                  );
                }
              }
            } else {
              // Normal case handling based on the key in headers
              const data = tableData.find((d) => Object.keys(d)[0] === key);

              if (data) {
                return (
                  <div
                    key={index}
                    className={`flex-1 border-r border-stroke dark:border-strokedark`}
                  >
                    <ListView header={label} data={data} />
                  </div>
                );
              }
            }

            return null; // Skip if no matching data found for the current header
          })}
        </div>
      </div>
    </div>
  );
};

export default VersionHistoryTable;
