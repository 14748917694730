import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import "./css/style.css";
import "./css/satoshi.css";
import "flatpickr/dist/flatpickr.min.css";
import "./index.css";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import { englishTranslation, germanTranslation } from './translation'

i18n.init({
  interpolation: { escapeValue: false }, 
  lng: 'de',                              
  resources: {
    en: {
      translation: englishTranslation
      
    },
    de: {
      translation: germanTranslation
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <App />
        <Toaster />
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>
);
