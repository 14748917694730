import React from "react";

const Modal = ({
  isOpen,
  onClose,
  children
}) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed left-0 top-0  flex h-full w-full items-center justify-center bg-black/90 px-4 py-5"
      style={{ zIndex: 9999 }}
    >
      <div
        className="relative w-full  rounded-lg dark:bg-boxdark-2 bg-[#F1F5F9] px-8 py-12 text-center"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div className="flex flex-col md:flex-row items-center justify-end gap-3 w-full">
         
        </div>
        {/* Modal Content */}
        <div className="mb-10 font-medium">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
