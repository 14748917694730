import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

const DateSelector = ({ handleDateChange, dates, loading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);
  const {t} = useTranslation()
  
  const regex = /^\d{2}\.\d{2}\.\d{4}$/;
  const filteredDates = !regex.test(searchTerm)
    ? dates.filter((date) => date.toLowerCase().includes(searchTerm.toLowerCase()))
    : dates; 

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleOptionSelect = (date) => {
    setSearchTerm(date);
    handleDateChange({ target: { value: date } }); // Notify parent component
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(true);
  };

  return (
    <div className="mx-auto my-8 w-full">
      <label htmlFor="datepicker" className="block text-gray-700 mb-2">
      {t("Select Date")}
      </label>
      <div className="relative" ref={dropdownRef}>
        <input
          type="text"
          placeholder={loading ? t("Loading...") : t("Select or Search Date")}
          value={searchTerm}
          onChange={handleInputChange}
          onFocus={toggleDropdown}
          className="relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
          disabled={loading}
        />
        {isOpen && !loading && (
          <ul className="absolute z-10 w-full dark:border-form-strokedark dark:bg-form-input bg-white border border-stroke rounded shadow-lg max-h-52 overflow-y-auto">
            {filteredDates.length > 0 ? (
              filteredDates.map((date, index) => (
                <li
                  key={index}
                  onClick={() => handleOptionSelect(date)}
                  className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                >
                  {date}
                </li>
              ))
            ) : (
              <li className="cursor-default px-4 py-2 text-gray-500">
               {t("No dates found")}
              </li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default DateSelector;
