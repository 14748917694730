import React, { useState, useRef, useEffect } from 'react';

const MultiSelectDropdown = ({ locations, formData, setFormData, inputChangeHandler }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null); // Create a ref for the dropdown

  const toggleDropdown = () => setIsOpen(!isOpen);

  const filteredLocations = locations.filter(location =>
    location.locationName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="relative" ref={dropdownRef}> {/* Attach ref to the dropdown container */}
      <label className='mb-2 block text-sm font-medium text-black dark:text-white' htmlFor="choose-location">Choose Location</label>
      <button
        onClick={toggleDropdown}
        className="flex text-left w-full rounded-md border border-stroke py-2 px-4 bg-transparent text-black dark:border-form-strokedark dark:bg-form-input dark:text-white outline-none focus:border-primary"
      >
        {formData.locations.length > 0 
          ? formData.locations.join(', ') 
          : "Choose Location"}
      </button>
      {isOpen && (
        <div className="absolute z-10 w-full max-h-72 overflow-y-auto bg-white border border-gray-300 rounded-md shadow-lg dark:bg-form-input">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 border-b border-gray-300 dark:border-form-strokedark dark:bg-form-input dark:text-white"
          />
          {filteredLocations.length === 0 ? (
            <div className="p-2 text-gray-500">No locations found</div>
          ) : (
            filteredLocations.map((location) => (
              <label key={location.id} className="flex items-center p-2 hover:bg-gray-100">
                <input
                  type="checkbox"
                  name="locations"
                  value={location.locationName}
                  checked={formData.locations.includes(location.locationName)}
                  onChange={inputChangeHandler}
                  className="mr-2"
                />
                {location.locationName}
              </label>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
