import React, { createContext, useState, useEffect, useRef } from 'react';
import {jwtDecode} from 'jwt-decode';
import { Navigate } from 'react-router-dom';
import Loader from '../common/Loader';
import axios from 'axios';
import { AUTH_BASE_URL } from '../constant';

const AuthContext = createContext();

const PrivateRoute = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [loading, setLoading] = useState(true);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const inactivityTimeoutRef = useRef(null);

  const INACTIVITY_TIMEOUT = 20 * 60 * 1000; // 20 minutes
  // const INACTIVITY_TIMEOUT = 60 * 1000; // 1 minute
  const TOKEN_CHECK_INTERVAL = 30 * 1000; // 30 seconds


  const logoutHistory = async () => {
    try {
      const response = await axios.put(`${AUTH_BASE_URL}/api/logout`, {username: user.username}, {
        headers: {
          'x-access-token': token
        }
      })
      
    }catch(err) {
      console.error(err)
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setIsTokenExpired(true);
      setLoading(false);
      return;
    }

    const checkTokenValidity = () => {
      try {
        const decoded = jwtDecode(token);
        const currentDate = new Date();
        setToken(token);

        // Check if token is expired
        if (decoded.exp < currentDate.getTime() / 1000) {
          setIsTokenExpired(true);
          if(user.role !== 'superadmin' && user.role !== 'admin') {
            logoutHistory()
          }
        
        } else {
          setUser({
            username: decoded.username,
            role: decoded.role,
            location: decoded.location,
          });
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        setIsTokenExpired(true);
      } finally {
        setLoading(false);
      }
    };

    checkTokenValidity();
    const intervalId = setInterval(checkTokenValidity, TOKEN_CHECK_INTERVAL);

    // Event listeners to detect user activity
    const handleUserActivity = () => {
      setLastActivityTime(Date.now());
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    // Clear interval and event listeners on component unmount
    return () => {
      clearInterval(intervalId);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, []);

  useEffect(() => {
    // If user is inactive for INACTIVITY_TIMEOUT, log them out
    const handleInactivity = () => {
      if (Date.now() - lastActivityTime > INACTIVITY_TIMEOUT) {
        setIsTokenExpired(true);
        if(user.role !== 'superadmin' && user.role !== 'admin')
          logoutHistory()
      }
    };

    inactivityTimeoutRef.current = setTimeout(handleInactivity, INACTIVITY_TIMEOUT);

    return () => {
      clearTimeout(inactivityTimeoutRef.current);
    };
  }, [lastActivityTime]);

  if (loading) {
    return <Loader />;
  }

  if (isTokenExpired) {
    localStorage.removeItem('token'); // Clear token from localStorage on expiration
    return <Navigate to="/session-expired" />;
  }

  return (
    <AuthContext.Provider value={{ user, setUser, token, logoutHistory}}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext };
export default PrivateRoute;
