import React, { useState, useEffect, useRef, useTransition } from "react";
import { useTranslation } from 'react-i18next';

const ExportDropdown = ({ exportData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(""); // State to store selected option
  const dropdownRef = useRef(null); // Ref to detect outside click
  const [exporting, setExporting] = useState(false);
  const {t} = useTranslation()

  // Toggle dropdown
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Handle radio button change
  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };

  // Handle submit button click
  const handleSubmit = () => {
    if (selectedOption) {
      exportData(selectedOption, setExporting);
    } else {
      alert("Please select an option");
    }
  };

  // Close dropdown if clicked outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  // Add event listener for clicks outside the dropdown
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const exportLabel = [t("No Follow Up"), t("No Documentation"), t("Patients without service2")];

  return (
    <div className="relative sm:w-fit w-full mb-3" ref={dropdownRef}>
      <button
        type="button"
        id="dropdownToggle"
        onClick={toggleDropdown}
        className="w-full px-5 py-2.5 rounded text-white text-sm font-semibold border-none outline-none bg-blue-600 hover:bg-blue-700 active:bg-blue-600"
      >
        {t("Export")}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-3 fill-white inline ml-3"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M11.99997 18.1669a2.38 2.38 0 0 1-1.68266-.69733l-9.52-9.52a2.38 2.38 0 1 1 3.36532-3.36532l7.83734 7.83734 7.83734-7.83734a2.38 2.38 0 1 1 3.36532 3.36532l-9.52 9.52a2.38 2.38 0 0 1-1.68266.69734z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {isOpen && (
        <div
          id="dropdownMenu"
          className="absolute right-0 block shadow-lg bg-white py-2 px-2 z-[9999] min-w-full w-max rounded max-h-96 overflow-auto"
        >
          <ul>
            {exportLabel.map((label, index) => (
              <li
                key={index}
                className="py-2.5 px-4 hover:bg-blue-50 rounded text-black text-sm cursor-pointer"
              >
                <div className="flex items-center">
                  <input
                    id={`radio${index + 1}`}
                    type="radio"
                    className="hidden peer"
                    value={label}
                    checked={selectedOption === label}
                    onChange={handleRadioChange}
                  />
                  <label
                    htmlFor={`radio${index + 1}`}
                    className="relative mr-3 flex items-center justify-center p-1 peer-checked:before:hidden before:block before:absolute before:w-full before:h-full before:bg-white w-5 h-5 cursor-pointer bg-blue-600 border rounded-full overflow-hidden"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-full fill-white"
                      viewBox="0 0 520 520"
                    >
                      <path d="M79.423 240.755a47.529 47.529 0 0 0-36.737 77.522l120.73 147.894a43.136 43.136 0 0 0 36.066 16.009c14.654-.787 27.884-8.626 36.319-21.515L486.588 56.773a6.13 6.13 0 0 1 .128-.2c2.353-3.613 1.59-10.773-3.267-15.271a13.321 13.321 0 0 0-19.362 1.343q-.135.166-.278.327L210.887 328.736a10.961 10.961 0 0 1-15.585.843l-83.94-76.386a47.319 47.319 0 0 0-31.939-12.438z" />
                    </svg>
                  </label>
                  {label}
                </div>
              </li>
            ))}
          </ul>

          {/* Submit button */}
          <button
            onClick={handleSubmit}
            disabled={exporting}
            className={`flex items-center justify-center w-full rounded p-3 font-medium text-gray text-sm ${
              exporting
                ? "bg-blue-600 cursor-not-allowed"
                : "bg-primary hover:bg-opacity-90"
            }`}
          >
            {exporting ? (
              <svg
                className="animate-spin h-5 w-5 mr-2 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8z"
                ></path>
              </svg>
            ) : null}
            {exporting ? "Exporting..." : "Export"}
          </button>
        </div>
      )}
    </div>
  );
};

export default ExportDropdown;
