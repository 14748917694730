import React from "react";
import PasswordInput from "./PasswordInput";
import { useTranslation } from "react-i18next";
import AddressDropdown from "./LocationDropdown";

const AddUserForm = ({
  inputChangeHandler,
  submitHandler,
  error,
  formData,
  setFormData,
  responseError,
  locations,
  submitting,
}) => {
  const { t } = useTranslation();
  return (
    <div className="p-6">
      {/* Response Messages */}
      {responseError && (
        <p className="text-red-600 mb-3 text-sm">{responseError}</p>
      )}

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
        {/* Username Field */}
        <div>
          <label className="mb-2 block text-sm font-medium text-black dark:text-white">
            Username
          </label>
          <input
            onChange={inputChangeHandler}
            type="text"
            name="username"
            value={formData.username}
            placeholder="Enter Username"
            className="w-full rounded-md border border-stroke py-2 px-4 text-black dark:border-form-strokedark dark:bg-form-input dark:text-white outline-none focus:border-primary"
          />
          {error.username && (
            <p className="text-red-600  text-sm mt-1">{error.username}</p>
          )}
        </div>

        {/* Password Field */}
        <div>
          <PasswordInput
            formData={formData}
            inputChangeHandler={inputChangeHandler}
          />
          {error.password && (
            <p className="text-red-600  text-sm mt-1">{error.password}</p>
          )}
        </div>

        {/* Role Field */}
        <div className="sm:col-span-2">
          <label className="mb-2 block text-sm font-medium text-black dark:text-white">
            Role
          </label>
          <select
            name="role"
            value={formData.role}
            onChange={inputChangeHandler}
            className="w-full rounded-md border border-stroke py-2 px-4 bg-transparent text-black dark:border-form-strokedark dark:bg-form-input dark:text-white outline-none focus:border-primary"
          >
            <option value="">Select Role</option>
            <option value="admin">Admin</option>
            <option value="normal">Normal</option>
          </select>
          {error.role && (
            <p className="text-red-600  text-sm mt-1">{error.role}</p>
          )}
        </div>

        {/* Location Field */}
        {formData.role === "normal" && (
          <div className="sm:col-span-2">
            <AddressDropdown
              locations={locations}
              formData={formData}
              setFormData={setFormData}
              inputChangeHandler={inputChangeHandler}
            />
            {error.locations && (
              <p className="text-red-600  text-sm mt-1">{error.locations}</p>
            )}
          </div>
        )}

        {/* Submit Button */}
        <div className="sm:col-span-2 flex justify-end text-sm">
          <button
            onClick={submitHandler}
            className="rounded-md flex bg-primary py-2 px-6 text-white hover:bg-primary-dark focus:outline-none"
          >
            {submitting ? (
              <svg
                className="animate-spin h-5 w-5 mr-2 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8z"
                ></path>
              </svg>
            ) : null}
            {submitting ? t("Adding User...") : t("Add User")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddUserForm;
