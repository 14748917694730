import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { AUTH_BASE_URL } from "../constant";
import axios from "axios";
import { AuthContext } from "./PrivateRoutes";
import ClipLoader from "react-spinners/ClipLoader";
import AddUserForm from "./AddUserForm";
import UpdateUserForm from "./UpdateUserForm";

const UserTable = () => {
  const initialState = {
    username: "",
    password: "",
    locations: [],
    role: "",
  };

  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const { token } = useContext(AuthContext);
  const [error, setError] = useState({});
  const [submit, setSubmit] = useState(false);
  const [responseError, setResponseError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [deletingUser, setDeletingUser] = useState({});
  const [expandedUser, setExpandedUser] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);


  const inputChangeHandler = (e) => {
    const { name, value, type } = e.target;
    if (type === 'checkbox') {
      const updatedLocations = formData.locations.includes(value)
        ? formData.locations.filter(item => item !== value)
        : [...formData.locations, value];

      setFormData({ ...formData, locations: updatedLocations });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };


  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${AUTH_BASE_URL}/api/users`, {
        headers: {
          "x-access-token": token,
        },
      });
      setUsers(response.data);
    } catch (err) {
      console.error(err);
      toast.error("Error in fetching users");
    } finally {
      setLoading(false);
    }
  };

  const fetchLocations = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${AUTH_BASE_URL}/api/locations`, {
        headers: {
          "x-access-token": token,
        },
      });
      setLocations(response.data);
    } catch (err) {
      console.error(err);
      toast.error("Error in fetching users");
    } finally {
      setLoading(false);
    }
  };

  const submitHandler = () => {
    setSubmit(true);
    setFormData({ ...formData });
    setError(validate(formData));
  };

  const validate = (formData) => {
    const error = {};
    if (!formData.username) {
      error.username = "Username is required";
    }
    if (!formData.password) {
      error.password = "Password is required";
    }
    if (formData.locations.length === 0 && formData.role !== "admin") {
      error.locations = "Choose at least one location";
    }
    if (!formData.role) {
      error.role = "Choose correct role";
    }
    return error;
  };

  const addUser = async () => {
    try {
      setSubmitting(true);
      await axios.post(`${AUTH_BASE_URL}/api/add-user`, formData, {
        headers: {
          "x-access-token": token,
        },
      });
      setFormData(initialState);
      fetchUsers(); // Refresh the user list after adding a user
    } catch (err) {
      console.error(err);
      setResponseError(err.response.data.message || "Unable to create user");
    } finally {
      setSubmitting(false);
    }
  };

  const deleteUser = async (userId) => {
    try {
      setDeletingUser((prev) => ({ ...prev, [userId]: true }));
      await axios.delete(`${AUTH_BASE_URL}/api/delete-user/${userId}`, {
        headers: {
          "x-access-token": token,
        },
      });
      toast.success("User deleted successfully");
      fetchUsers(); // Refresh the user list after deleting a user
    } catch (err) {
      console.error(err);
      toast.error("Unable to delete user");
    } finally {
      setDeletingUser((prev) => ({ ...prev, [userId]: false }));
    }
  };


  const handleUpdateClick = (user) => {
    setSelectedUser(user);
    setFormData({
      username: user.username,
      password: user.password,
      locations: user.locations,
      role: user.role,
    });
    setIsUpdateModalOpen(true);
  };

  const updateUser = async () => {
    try {
      setSubmitting(true);
      await axios.put(
        `${AUTH_BASE_URL}/api/update-user/${selectedUser.id}`,
        formData,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      setFormData(initialState);
      setIsUpdateModalOpen(false);
      fetchUsers();
      toast.success("User updated successfully");
    } catch (err) {
      console.error(err);
      setResponseError(err.response?.data?.message || "Unable to update user");
    } finally {
      setSubmitting(false);
    }
  };
  

  const toggleLocations = (userId) => {
    // Toggle the expanded user state
    setExpandedUser(expandedUser === userId ? null : userId);
  };

  useEffect(() => {
    if (Object.keys(error).length === 0 && submit) {
      addUser();
    }
  }, [error]);

  useEffect(() => {
    fetchUsers();
    fetchLocations()
  }, []);

  return (
    <div className="relative rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <div className="flex flex-wrap items-center justify-between">
        <h3 className="mb-6 font-medium text-xl text-black dark:text-white">
          {t("Users")}
        </h3>
        <button
          className="mb-6 w-fit px-5 py-2.5 rounded text-white text-sm font-semibold border-none outline-none bg-blue-600 hover:bg-blue-700 active:bg-blue-600"
          onClick={() => setIsModalOpen(true)}
        >
          <i className="fa-solid fa-plus"></i> {t("Add User")}
        </button>
      </div>

      {/* Display loader when loading is true */}
      {loading ? (
        <div className="flex justify-center items-center h-40">
          <ClipLoader color="#3C50E0" loading={loading} size={50} />
        </div>
      ) : (
        <div className="overflow-x-auto">
          <div className="flex flex-col">
            <div className="grid grid-cols-3 rounded-sm bg-gray-2 dark:bg-meta-4 sm:grid-cols-5 min-w-[600px]">
              <div className="p-2.5 text-center xl:p-5">
                <h5 className="text-sm text-left uppercase xsm:text-base">
                  Username
                </h5>
              </div>
              <div className="p-2.5 text-center xl:p-5">
                <h5 className="text-sm text-left uppercase xsm:text-base">
                  Password
                </h5>
              </div>
              <div className="p-2.5 text-center xl:p-5">
                <h5 className="text-sm text-left uppercase xsm:text-base">
                  Location
                </h5>
              </div>
              <div className="hidden p-2.5 text-center sm:block xl:p-5">
                <h5 className="text-sm text-left uppercase xsm:text-base">
                  Role
                </h5>
              </div>
              <div className="p-2.5 text-center xl:p-5">
                <h5 className="text-sm text-left uppercase xsm:text-base">
                  Actions
                </h5>
              </div>
            </div>

            {users.map((user, key) => {
              if (user.username === "superadmin" || user.username === "admin")
                return <div key={key}></div>;
              return (
                <div
                  className={`grid grid-cols-3 sm:grid-cols-5 min-w-[600px] ${
                    key === user.length - 1
                      ? ""
                      : "border-b border-stroke dark:border-strokedark"
                  }`}
                  key={key}
                >
                  <div className="p-2.5 xl:p-5">
                    <p className="text-black text-left dark:text-white">
                      {user.username}
                    </p>
                  </div>

                  <div className="p-2.5 xl:p-5">
                    <p className="text-black text-left dark:text-white">
                      {user.password}
                    </p>
                  </div>

                  <div className="flex flex-col items-center gap-2 p-2.5 xl:p-5">
                    <button
                      className="px-4 flex py-1 rounded text-sm bg-blue-500 text-white"
                      onClick={() => toggleLocations(user.id)}
                    >
                      {expandedUser === user.id
                        ? "Hide Locations"
                        : "Show Locations"}
                    </button>
                    {/* Show locations list if expanded */}
                    {expandedUser === user.id && (
                      <ul className="mt-1 text-black dark:text-white list-decimal list-inside">
                        {user.locations.map((location, index) => (
                          <li className="whitespace-nowrap" key={index}>{location}</li>
                        ))}
                      </ul>
                    )}
                  </div>

                  <div className="p-2.5 xl:p-5">
                    <p
                      className={`flex items-center justify-center rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${
                        user.role === "admin"
                          ? "bg-success text-success"
                          : user.role === "normal"
                          ? "bg-danger text-danger"
                          : "bg-warning text-warning"
                      }`}
                    >
                      {user.role}
                    </p>
                  </div>

                  {/* Actions Column */}
                  <div className="flex items-start gap-2 p-2.5 xl:p-5">
                    <button
                      key={key}
                      className="px-4 flex py-1 rounded text-sm bg-red-500 text-white"
                      onClick={() => deleteUser(user.id)}
                    >
                      {deletingUser[user.id] ? (
                        <svg
                          className="animate-spin h-5 w-5 mr-2 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8v8z"
                          ></path>
                        </svg>
                      ) : null}
                      {deletingUser[user.id] ? t("Deleting...") : t("Delete")}
                    </button>
                    <button
                      key={key}
                      className="px-4 flex py-1 rounded text-sm bg-primary text-white"
                      onClick={() => handleUpdateClick(user)}
                    >
                      {t("Edit")}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {isModalOpen && (
        <div
          className="fixed inset-0 flex w-full items-center justify-center bg-black bg-opacity-50"
          style={{ zIndex: 10000}}
        >
          <div className="relative w-full max-w-4xl p-6 bg-white rounded-md shadow-lg dark:bg-boxdark">
            {/* Modal Header */}
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-lg font-semibold text-black dark:text-white">
                Add New User
              </h4>
              <button
                className="text-black dark:text-white hover:text-red-500"
                onClick={() => setIsModalOpen(false)}
              >
                ✕
              </button>
            </div>
            {/* AddUserForm Component */}
            <AddUserForm
              formData={formData}
              setFormData={setFormData}
              setIsModalOpen={setIsModalOpen}
              inputChangeHandler={inputChangeHandler}
              submitHandler={submitHandler}
              error={error}
              responseError={responseError}
              locations={locations}
              submitting={submitting}
              selectedLocations={selectedLocations} 
              setSelectedLocations={setSelectedLocations} 
            />
          </div>
        </div>
      )}

       {/* Update User Modal */}
       {isUpdateModalOpen && (
        <div className="fixed inset-0 flex w-full items-center justify-center bg-black bg-opacity-50" style={{ zIndex: 10000 }}>
          <div className="relative w-full max-w-4xl p-6 bg-white rounded-md shadow-lg dark:bg-boxdark">
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-lg font-semibold text-black dark:text-white">
                Update User
              </h4>
              <button
                className="text-black dark:text-white hover:text-red-500"
                onClick={() => setIsUpdateModalOpen(false)}
              >
                ✕
              </button>
            </div>
            <UpdateUserForm
              formData={formData}
              setFormData={setFormData}
              setIsUpdateModalOpen={setIsUpdateModalOpen}
              inputChangeHandler={inputChangeHandler}
              submitHandler={updateUser}
              error={error}
              responseError={responseError}
              locations={locations}
              submitting={submitting}
              selectedLocations={selectedLocations}
              setSelectedLocations={setSelectedLocations}
              isAdmin={selectedUser?.role === 'admin'}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserTable;
