import React from "react";
import { convertDateFormat } from "../utils";

const ListView = ({ header, data }) => {
  header = header.replace('<br />', '')
  const values = Object.values(data)[0];
  if (values.length === 0) {
    return <div></div>;
  }
  return (
    <div className="p-3">
      <p className="p-2 xl:p-3 text-sm font-bold xsm:text-base md:sticky md:left-0 z-20 text-nowrap text-left">{header}</p>
      
      <ul className="list-none space-y-2">
        {values.length > 0 && values.map((value, index) => (
          <li key={index} className="p-2 text-nowrap text-left">
            {value[Object.keys(value).filter((key) => key !== 'Location' && key !== 'createdAt')]}<span className="text-sm">{" ("+ convertDateFormat(value['createdAt']).replaceAll('-','.')+")"}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListView;
