import React, { useState, useRef } from "react";

const PasswordInput = ({inputChangeHandler, formData}) => {
  const generateRandomPassword = () => {
    // Generate an 8-character alphanumeric password
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let newPassword = "";
    for (let i = 0; i < 8; i++) {
      newPassword += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    inputChangeHandler({target: {name: "password", value: newPassword}})
  };

  return (
    <div className="relative w-full max-w-sm">
      <label className="mb-2 block text-sm font-medium text-black dark:text-white">
        Password
      </label>
      <div className="relative">
        <input
          type="text"
          name="password"
          placeholder="Enter Password"
          onChange={inputChangeHandler}
          value={formData.password}
          className="w-full rounded-md border border-stroke py-2 pr-20 pl-4 text-black outline-none transition duration-300 ease-in-out focus:border-primary dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
        />
        <button
          type="button"
          onClick={generateRandomPassword}
          className="absolute right-2 text-sm top-1/2 transform -translate-y-1/2 px-4 py-1 bg-grey-500 rounded-md focus:outline-none text-black-2 dark:text-white"
        >
          Generate
        </button>
      </div>
    </div>
  );
};

export default PasswordInput;
