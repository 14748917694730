import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const options = [
  {
    value: "de",
    label: (
      <div className="flex items-center">
        <span className="fi fi-de mr-2"></span>DE
      </div>
    ),
  },
  {
    value: "en",
    label: (
      <div className="flex items-center">
        <span className="fi fi-us mr-2"></span>EN
      </div>
    ),
  },
];

const LanguageDropdown = () => {
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng.value);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: "0.5rem",
      borderRadius: "0.375rem",
      backgroundColor: "inherit",
      borderColor: "inherit",
      boxShadow: state.isFocused ? "none" : provided.boxShadow,
      transition: "all 0.2s ease-in-out",
      "&:hover": {
        borderColor: "inherit",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'inherit',
      borderRadius: "0.375rem",
      boxShadow: "0 10px 15px rgba(0, 0, 0, 0.1)",
      overflow: "hidden",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "inherit" : "inherit",
      color: "inherit",
      padding: "0.5rem 1rem",
      cursor: "pointer",
      transition: "background-color 0.2s ease-in-out",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "inherit",
      fontWeight: "500",
    }),
  };

  return (
    <Select
      options={options}
      styles={customStyles}
      defaultValue={options[0]}
      isSearchable={false}
      className="w-full max-w-xs mx-auto bg-white dark:bg-boxdark dark:drop-shadow-none"
      onChange={changeLanguage}
    />
  );
};

export default LanguageDropdown;
