import React from "react";

const Dialog = ({
  isOpen,
  onClose,
  children
}) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black/80 px-4 py-5"
      style={{ zIndex: 9999 }}
      onClick={onClose}
    >
      <div
        className="relative w-full max-w-sm md:max-w-lg lg:max-w-xl rounded-lg dark:bg-boxdark-2 bg-white p-6 md:p-8 shadow-lg transform transition-transform duration-300 ease-in-out scale-100"
        onClick={(e) => e.stopPropagation()} // Prevents the modal from closing when clicking inside
      >
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        {/* Modal Content */}
        <div className="flex flex-col items-center justify-center">
          <div className="mb-5 font-medium text-lg">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dialog;
