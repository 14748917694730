import React from 'react'
import UserTable from '../components/UserTable'

const User = () => {
  return (
    <div className="w-full flex justify-center">
    <div className="max-w-7xl w-full">
      <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
        <UserTable />
      </div>
    </div>
  </div>
  )
}

export default User