import React from 'react';

const SessionExpired = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-50 to-blue-100 dark:from-gray-800 dark:to-gray-900">
      <div className="w-full max-w-lg p-8 bg-white rounded-2xl shadow-2xl dark:bg-gray-800">
        <div className="text-center">
          <div className="mb-6">
            <svg
              className="w-16 h-16 text-yellow-500 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 8v4l3 3m6 0a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          </div>
          <h2 className="text-3xl font-extrabold text-gray-800 dark:text-white">Session Expired</h2>
          <p className="mt-4 text-lg text-gray-600 dark:text-gray-300">
            For your security, your session has timed out. Please log in again to continue.
          </p>
          <button
            onClick={() => window.location.href = '/login'}
            className="mt-8 w-full py-3 px-5 text-lg font-semibold text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800 transition duration-150"
          >
            Return to Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default SessionExpired;
