import React, { useEffect, useState, useContext } from "react";
import apiVersionHistory from "../services/apiVersionHistory";
import { BASE_URL, DASHBOARD_BASE_URL } from "../constant";
import DateSelector from "../components/DateSelector";
import toast from "react-hot-toast";
import Modal from "../components/Modal";
import TableNameDropdown from "../components/SelectGroup/TableNameDropdown";
import { AuthContext } from "../components/PrivateRoutes";
import VersionHistoryTable from "../components/VersionHistoryTable";
import { useTranslation } from "react-i18next";

const VersionHistory = () => {
  const [tableNames, setTableNames] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedTableName, setSelectedTableName] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submit, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addressInfo, setAddressInfo] = useState([]);
  const [selectedAddresses, setSelectedAddresses] = useState([]);
  const { user, token } = useContext(AuthContext);
  const {t} = useTranslation()

  useEffect(() => {
    const fetchTableNames = async () => {
      try {
        const response = await apiVersionHistory.get(
          `${DASHBOARD_BASE_URL}/api/table-names`,
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        setTableNames(response.data);
      } catch (err) {
        console.error("Error fetching table names:", err);
        toast.error("Error fetching table names");
      }
    };

    fetchTableNames();
  }, []);

  useEffect(() => {
    if (selectedTableName) {
      const fetchDates = async () => {
        setLoading(true);
        try {
          const response = await apiVersionHistory.get(
            `${DASHBOARD_BASE_URL}/api/date/${selectedTableName}`,
            {
              headers: {
                "x-access-token": token,
              },
            }
          );
          setDates(response.data);
        } catch (err) {
          console.error("Error fetching dates:", err);
          toast.error("Error fetching dates");
        }
        setLoading(false);
      };

      fetchDates();
    }
  }, [selectedTableName]);

  const handleTableChange = (event) => {
    setSelectedTableName(event.target.value);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const response = await apiVersionHistory.post(
        `${DASHBOARD_BASE_URL}/api/get-version-history`,
        {
          tableName: selectedTableName,
          date: selectedDate,
          location: selectedAddresses,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      setTableData(response.data)
      setIsModalOpen(true);
    } catch (err) {
      console.error("Error fetching data:", err);
      toast.error("Error fetching data");
    }
    setSubmitting(false);
  };

  const fetchAddressInfo = async () => {
    try {
      const response = await apiVersionHistory.get(`${BASE_URL}/api/addresses`, {
        headers: {
          "x-access-token": token,
        },
      });
      setAddressInfo(response.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    fetchAddressInfo();
  }, []);

  return (
    <div className="w-full flex justify-center">
      <div className="max-w-4xl w-full rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-xl text-black dark:text-white">
            {t("Version History")}
          </h3>
        </div>
        <form action="#">
          <div className="p-6.5">
            <TableNameDropdown
              tableNames={tableNames}
              selectedTableName={selectedTableName}
              handleTableChange={handleTableChange}
            />
            <DateSelector
              handleDateChange={handleDateChange}
              dates={dates}
              loading={loading}
            />
            <div className="mb-5">
              <label className="mb-2.5 block text-gray-700">
                {" "}
               { t("Select Location")}
              </label>
              <select
                className="z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="addresses"
                onChange={(e) => setSelectedAddresses(e.target.value)}
              >
                <option value="">{t("Select Address")}</option>
                {addressInfo.map((address, key) => (
                  <option key={key} value={address.displayName}>
                    {address.displayName}
                  </option>
                ))}
              </select>
            </div>

            <button
              onClick={handleSubmit}
              className={`uppercase flex items-center justify-center w-full rounded p-3 font-medium text-gray text-sm ${
                submit
                  ? "bg-blue-600 cursor-not-allowed"
                  : "bg-primary hover:bg-opacity-90"
              }`}
              disabled={submit}
            >
              {submit ? (
                <svg
                  className="animate-spin h-5 w-5 mr-2 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8z"
                  ></path>
                </svg>
              ) : null}
              {submit ? t("Wait...") : t("View History")}
            </button>
          </div>
        </form>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="w-full h-full p-0">
          <VersionHistoryTable selectedDate={selectedDate} tableData={tableData} selectedAddresses={selectedAddresses} />
        </div>
      </Modal>
    </div>
  );
};

export default VersionHistory;
