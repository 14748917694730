import * as XLSX from "xlsx";
import {jsPDF} from 'jspdf'
import autoTable from "jspdf-autotable";

const MAX_CHAR_LIMIT = 32767;

// Helper function to split long text values into chunks for multiple rows
const splitTextIntoRows = (text, key, row) => {
  const parts = [];
  while (text.length > MAX_CHAR_LIMIT) {
    const newRow = { ...row };  // Create a copy of the original row
    newRow[key] = text.substring(0, MAX_CHAR_LIMIT); // Split text up to the limit
    parts.push(newRow); // Add it to the parts array
    text = text.substring(MAX_CHAR_LIMIT); // Reduce the original text
  }
  const finalRow = { ...row };  // Create a final row for the remaining text
  finalRow[key] = text; 
  parts.push(finalRow);
  return parts;
};

// Function to handle long text in data
const handleLongTextInData = (data) => {
  const processedData = [];
  data.forEach((row) => {
    let rowHasLongText = false;
    for (const key in row) {
      if (typeof row[key] === "string" && row[key].length > MAX_CHAR_LIMIT) {
        rowHasLongText = true;
        // Split long text into multiple rows
        processedData.push(...splitTextIntoRows(row[key], key, row));
        break;  // We already split this row, so break out of the loop
      }
    }
    if (!rowHasLongText) {
      processedData.push(row);  // Add row as-is if no long text
    }
  });
  return processedData;
};


const processExcelData = (data) => {
  
  let cleanedData = data.map(item => {
    // Replace unwanted characters and split lines
    return item?.ExcelData
      ?.replace(/_x000d_/g, '')  // Remove Excel carriage returns
      ?.replace(/\\r/g, '#')    // Replace carriage returns
      ?.replace(/\\\c\d+/g, '$') // Replace column placeholders
      ?.replace(/Column\d+/g, '')
  });

  // Split each cleaned data item into an array of rows, splitting by new lines
  cleanedData = cleanedData.map(item => item?.replace(/"/g, '')?.replace(/,/g, '')?.replace(/\\/g, '')?.trim()?.split('#')?.map(row => 
    row?.split('$')  // Split by column placeholder
      ?.map(cell => cell?.trim())  // Trim spaces from each cell
  ))
  return cleanedData[0] === undefined ? [] : cleanedData[0];
};


export const exportToExcel = (data, fileName, zip) => {
  const workbook = XLSX.utils.book_new();

  if (data.Sheet1 && data.Sheet2) {
    // Process Sheet1 using handleLongTextInData
    const processedSheet1 = handleLongTextInData(data.Sheet1);
    const worksheet1 = XLSX.utils.json_to_sheet(processedSheet1);
    XLSX.utils.book_append_sheet(workbook, worksheet1, "Sheet1");

    // Process Sheet2 using processExcelData and aoa_to_sheet
    const processedSheet2 = processExcelData(data.Sheet2);
    const worksheet2 = XLSX.utils.aoa_to_sheet(processedSheet2); 
    XLSX.utils.book_append_sheet(workbook, worksheet2, "Sheet2");
  } else {
    // Process a single sheet using handleLongTextInData
    if(data.length > 0 && data[0].hasOwnProperty('ExcelData')) {
      const processedData = processExcelData(data);
      const worksheet = XLSX.utils.aoa_to_sheet(processedData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    }else {
      const processedData = handleLongTextInData(data);
      const worksheet = XLSX.utils.json_to_sheet(processedData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    }
    
  }

  // Write the Excel buffer
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  // Determine filename suffix from available data
  let createdAt, date;
  if (data.Sheet1 && data.Sheet2) {
    if (data.Sheet1.length > 0) {
      createdAt = data?.Sheet1[0]?.createdAt;
      date = data?.Sheet1[0]?.Date;
    }
  } else if (data.length > 0) {
    createdAt = data[0]?.createdAt;
    date = data[0]?.From_Date || data[0]?.Date;
  }

  // Save file to ZIP
  zip.file(`${fileName}_${date}_${createdAt}.xlsx`, excelBuffer);
};



// ======================================================================================================


export const exportToPdf = (data, fileName, zip) => {
  const doc = new jsPDF();
  
  if (data.Sheet1 && data.Sheet2) {
    const processedSheet1 = handleLongTextInData(data.Sheet1);
    const processedSheet2 = handleLongTextInData(data.Sheet2);

    // Add data from Sheet1 to PDF
    if (processedSheet1.length > 0) {
      addSheetToPdf(doc, processedSheet1, "Sheet1");
      doc.addPage(); // New page for next sheet
    }

    // Add data from Sheet2 to PDF
    if (processedSheet2.length > 0) {
      addSheetToPdf(doc, processedSheet2, "Sheet2");
    }
  } else {
    const processedData = handleLongTextInData(data);
    if (processedData.length > 0) {
      addSheetToPdf(doc, processedData, "Sheet1");
    }
  }

  let createdAt, date;
  if (data.Sheet1 && data.Sheet2) {
    if (data.Sheet1.length > 0) {
      createdAt = data?.Sheet1[0]?.createdAt;
      date = data?.Sheet1[0]?.Date;
    }
  } else if (data.length > 0) {
    createdAt = data[0]?.createdAt;
    date = data[0]?.From_Date || data[0]?.Date;
  }

  // Create PDF as a blob and add to zip
  const pdfBlob = doc.output("blob");
  zip.file(`${fileName}_${date}_${createdAt}.pdf`, pdfBlob);
};

const addSheetToPdf = (doc, sheetData, sheetName) => {
  if (!sheetData || sheetData.length === 0) {
    console.error(`No data available for ${sheetName}`);
    return;
  }

  // Extract headers from the first row of the sheetData
  const headers = Object.keys(sheetData[0]).map((key) => ({
    title: key,
    dataKey: key,
  }));

  // Map rows for autoTable
  const rows = sheetData.map((row) => Object.values(row));

  // Add sheet name as a title
  doc.text(sheetName, 14, 20);

  // Use autoTable to add the table
  autoTable(doc, {
    head: [headers], // Headers
    body: rows,      // Row data
    startY: 30,      // Adjust the starting Y position to leave space below the title
    theme: 'grid',   // Add a grid around table cells for better visibility
    styles: {
      fontSize: 10,  // Adjust font size for better visibility
    },
    headStyles: {
      fillColor: [100, 100, 255], // Custom header background color for visibility
    },
    bodyStyles: {
      fillColor: [240, 240, 240], // Alternate row colors for readability
    },
  });
};







// ======================================================================================================



export const calculatePercentage = (value, appointment) => {
  if (appointment === "0" || appointment === 0) return "0.00%";
  const percentage = (parseInt(value) / parseInt(appointment)) * 100;
  // const cal1 = 100 / parseInt(appointment);
  // const cal2 = parseInt(appointment) - parseInt(treatment);
  // if (isNaN(cal1) || isNaN(cal2)) {
  //   return "0";
  // }

  return `${parseInt(percentage)}%`;
};

export const calculateDifference = (treatment, appointment) => {
  return parseInt(appointment) - parseInt(treatment);
};


export const convertDateFormat = (inputDate) => {
  const date = new Date(inputDate);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}-${month}-${year} ${hours}:${minutes}`;
};