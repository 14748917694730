import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AUTH_BASE_URL } from "../constant";
import axios from "axios";
import { AuthContext } from "../components/PrivateRoutes";
import { useTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";

const LoginHistory = () => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedUser, setExpandedUser] = useState(null);

  const { token } = useContext(AuthContext);
  const { t } = useTranslation();

  const toggleLocations = (uniqueKey) => {
    // Toggle the expanded user state
    setExpandedUser(expandedUser === uniqueKey ? null : uniqueKey);
  };

  const getLoginHistory = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${AUTH_BASE_URL}/api/login-history`, {
        headers: {
          "x-access-token": token,
        },
      });
      setHistory(response.data);
    } catch (err) {
      console.error(err);
      toast.error("Error in fetching login history");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLoginHistory();
  }, []);

  return (
    <div className="relative rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <h3 className="mb-6 font-medium text-xl text-black dark:text-white">
        {t("Login History")}
      </h3>

      <div className="overflow-x-auto">
        {loading ? (
          <div className="flex justify-center items-center h-40">
            <ClipLoader color="#3C50E0" loading={loading} size={50} />
          </div>
        ) : (
          <div className="flex flex-col">
            <div className="grid grid-cols-3 rounded-sm bg-gray-2 dark:bg-meta-4 sm:grid-cols-5 min-w-[600px]">
              <div className="p-2.5 text-center xl:p-5">
                <h5 className="text-sm text-left uppercase xsm:text-base">
                  Username
                </h5>
              </div>
              <div className="p-2.5 text-center xl:p-5">
                <h5 className="text-sm text-left uppercase xsm:text-base">
                  Location
                </h5>
              </div>
              <div className="hidden p-2.5 text-center sm:block xl:p-5">
                <h5 className="text-sm text-left uppercase xsm:text-base">
                  Role
                </h5>
              </div>
              <div className="p-2.5 text-center xl:p-5">
                <h5 className="text-sm text-left uppercase xsm:text-base">
                  Login Time
                </h5>
              </div>
              <div className="p-2.5 text-center xl:p-5">
                <h5 className="text-sm text-left uppercase xsm:text-base">
                  Logout Time
                </h5>
              </div>
            </div>

            {history.map((user, key) => {
              const uniqueKey = `${user.userId}-${key}`;
              return (
                <div
                  className={`grid grid-cols-3 sm:grid-cols-5 min-w-[600px] ${
                    key === history.length - 1
                      ? ""
                      : "border-b border-stroke dark:border-strokedark"
                  }`}
                  key={uniqueKey}
                >
                  <div className="p-2.5 xl:p-5">
                    <p className="text-black text-left dark:text-white">
                      {user.username}
                    </p>
                  </div>
                  <div className="flex flex-col items-center gap-2 p-2.5 xl:p-5">
                    <button
                      className="px-4 flex py-1 rounded text-sm bg-blue-500 text-white"
                      onClick={() => toggleLocations(uniqueKey)}
                    >
                      {expandedUser === uniqueKey
                        ? "Hide Locations"
                        : "Show Locations"}
                    </button>
                    {/* Show locations list if expanded */}
                    {expandedUser === uniqueKey && (
                      <ul className="mt-1 text-black dark:text-white list-decimal list-inside">
                        {user.locations.map((location, index) => (
                          <li className="whitespace-nowrap" key={index}>
                            {location}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <div className="p-2.5 xl:p-5">
                    <p
                      className={`flex items-center justify-center rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${
                        user.role === "admin"
                          ? "bg-success text-success"
                          : user.role === "normal"
                          ? "bg-danger text-danger"
                          : "bg-warning text-warning"
                      }`}
                    >
                      {user.role}
                    </p>
                  </div>
                  <div className="p-2.5 xl:p-5">
                    <p className="text-black text-left dark:text-white">
                      {new Date(user.login).toLocaleDateString(undefined, {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}{" "}
                      {new Date(user.login).toLocaleTimeString(undefined, {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                        timeZone: "UTC",
                      })}
                    </p>
                  </div>
                  <div className="p-2.5 xl:p-5">
                    <p className="text-black text-left dark:text-white">
                      {user.logout ? (
                        <>
                          {new Date(user.logout).toLocaleDateString(undefined, {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}{" "}
                          {new Date(user.logout).toLocaleTimeString(undefined, {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                            timeZone: "UTC",
                          })}
                        </>
                      ) : (
                        "--"
                      )}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginHistory;
