import React, { useContext, useState } from "react";
import Header from "../components/Header/index";
import Sidebar from "../components/Sidebar/index";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Dialog from "../components/Dialog";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../components/PrivateRoutes";

const DefaultLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false); // State to track logout process
  const navigate = useNavigate();
  const {t} = useTranslation()
  const {logoutHistory, user} = useContext(AuthContext)
  const logoutHandler = () => {
    setIsDialogOpen(true); // Show the dialog on logout button click
  };

  const confirmLogout = () => {
    const token = localStorage.getItem("token");
    if (token) {
      setLoggingOut(true); // Set loading state to true
      if(user.role !== 'superadmin' && user.role !== 'admin') logoutHistory()
      setTimeout(() => {
        localStorage.removeItem("token");
        navigate("/login");
      }, 3000); // Simulate a delay of 3 seconds for the loading process
    }
  };

  const cancelLogout = () => {
    setIsDialogOpen(false);
    setLoggingOut(false); // Reset the logging out state if the user cancels
  };

  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark">
      {/* ===== Page Wrapper Start ===== */}
      <div className="flex h-screen overflow-hidden">
        {/* ===== Sidebar Start ===== */}
        <Sidebar logoutHandler={logoutHandler} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* ===== Sidebar End ===== */}

        {/* ===== Content Area Start ===== */}
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          {/* ===== Header Start ===== */}
          <Header logoutHandler={logoutHandler} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          {/* ===== Header End ===== */}

          {/* ===== Main Content Start ===== */}
          <main>
            <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
              <Outlet />
            </div>
          </main>
          {/* ===== Main Content End ===== */}
        </div>
        {/* ===== Content Area End ===== */}
      </div>
      {/* ===== Page Wrapper End ===== */}

      {/* Dialog for confirming logout */}
      <Dialog isOpen={isDialogOpen} onClose={cancelLogout}>
        <h2 className="text-xl font-semibold">{t("Confirm Logout")}</h2>
        <p className="mt-4">{t("Are you sure you want to log out?")}</p>
        <div className="mt-6 flex justify-center gap-4">
          {/* If loggingOut is true, show loader, otherwise show the button */}
          <button
            onClick={confirmLogout}
            disabled={loggingOut}
            className={`flex items-center justify-center whitespace-nowrap w-full rounded p-3 font-medium text-gray text-sm ${
              loggingOut
                ? "bg-red-600 cursor-not-allowed"
                : "bg-red-500 hover:bg-opacity-90"
            }`}
          >
            {loggingOut ? (
              <svg
                className="animate-spin h-5 w-5 mr-2 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8z"
                ></path>
              </svg>
            ) : null}
            {loggingOut ? t("Logging Out...") : t("Yes Logout")}
          </button>

          <button
            onClick={cancelLogout}
            className="flex items-center justify-center w-full rounded p-3 font-medium text-gray text-sm  bg-blue-500  hover:bg-blue-600"
            disabled={loggingOut} // Disable the cancel button while logging out
          >
            {t("Cancel")}
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default DefaultLayout;
